body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'NCTTorin-Light';
  src: url('assets/fonts/NCTTorin-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NCTTorin-Regular';
  src: url('assets/fonts/NCTTorin-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NCTTorin-Bold';
  src: url('assets/fonts/NCTTorin-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NCTTorin-ExtraBold';
  src: url('assets/fonts/NCTTorin-ExtraBold.ttf') format('truetype');
}

:root {
  --color-darkest: #ffffff;
  --color-dark: #7b8186;
  --color-btn: #304c70;
  --color-medium: #ced4da;
  --color-light: #f1f3f5;
  --color-btn-dark: #000000;

  --color-theme: #0a8f27;
  --color-accent: #8b2424;
}

@import url('https://fonts.googleapis.com/css2?family=Codystar&display=swap');

/* html {
  font-size: 62.5%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */

.wrapper {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;
}
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 30vw);
  position: relative;
}
.headerWrapper {
  position: relative;
  top: -30%;
}
.main {
  width: calc(95vmin);
  margin: 0 auto;
}

.app-header {
  width: calc(80%);
  margin: 0 auto;
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 3rem;
}
.error {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 2rem;
  background-color: #495057;
  border-radius: 100px;
}

h1 {
  font-family: 'NCTTorin-Regular';
  font-size: calc(98vmin - 93vmin);
  text-align: center;
  color: white;
}
h2 {
  font-size: calc(100vmin - 96vmin);
  color: white;
}

h3 {
  font-size: calc(100vmin - 97.5vmin);
  font-weight: 600;
  margin-bottom: 4rem;
  text-align: center;
  color: white;
}

h4 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2.4rem;
  color: white;
}

.start {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Adjust the gap value as needed */
}


.progress {
  margin-bottom: 4rem;
  display: grid;
  justify-content: space-between;
  gap: 1.2rem;
  grid-template-columns: auto auto;
  font-size: calc(100vmin - 97vmin);
  color: var(--color-medium);
}

progress {
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  grid-column: 1 / -1;
}

::-webkit-progress-bar {
  background-color: var(--color-medium);
  border-radius: 100px;
}
::-webkit-progress-value {
  background-color: var(--color-theme);
  border-radius: 100px;
}

.btn {
  display: block;
  font-family: inherit;
  color: inherit;
  font-size: 20px;
  border: 2px solid var(--color-dark);
  background-color: var(--color-dark);
  padding: 1.2rem 1.6rem;
  cursor: pointer;
  border-radius: 20px;
  transition: 0.1s;
  margin: 1rem 0rem 0rem 0rem;
}

.btn:not([disabled]):hover {
  background-color: var(--color-btn-dark);
}

.btn-option:not([disabled]):hover {
  background-color: var(--color-darkest);
}

.btn-option:not([disabled]):hover {
  transform: translateX(0.5rem);
}

.btn[disabled]:hover {
  cursor: not-allowed;
}

.btn-ui {
  float: right;
  font-weight: bold;
  background-color: var(--color-btn);
  color: var(--color-medium);
}

.options {
  display: flex;
  flex-direction: column;
}

.btn-option {
  width: 100%;
  text-align: left;
  margin-bottom: 0rem;
  font-size: 1.2rem;
  margin-left: 0rem;
}

.btn-option.correct {
  background-color: var(--color-theme);
  border: 2px solid var(--color-theme);
  color: var(--color-light);
}
.btn-option.wrong {
  background-color: var(--color-accent);
  border: 2px solid var(--color-accent);
  color: var(--color-darkest);
}

.answer {
  transform: translateX (2rem);
}
.result_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.result {
  background-color: var(--color-theme);
  color: var(--color-light);
  border-radius: calc(2vmin + 7px);
  text-align: center;
  padding: calc(2vmin + 11px) calc(2vmin + 9px);
  font-size: calc(2vmin + 7px);
  font-weight: 500;
  margin-bottom: 1.6rem;
}

.result span {
  display: block;
  font-size: 2.2rem;
  margin-right: 4px;
}

.highscore {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 4.8rem;
  color: white;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  gap: 1.6rem;

  color: var(--color-medium);
  font-size: 1.4rem;
}

.timer {
  float: left;
  font-size: 20px;
  color: var(--color-medium);
  border: 2px solid var(--color-dark);
  padding: 1.2rem 2rem;
  border-radius: 20px;
  margin: 1rem 0rem 0rem 0rem;
  font-weight: bold;
}

/* CREDIT: https://dev.to/afif/i-made-100-css-loaders-for-your-next-project-4eje */
.loader {
  width: 50px;
  height: 24px;
  background:
    radial-gradient(circle closest-side, currentColor 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, currentColor 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, currentColor 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 12px;
  background-repeat: no-repeat;
  animation: loader 1s infinite linear;
}

@keyframes loader {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }
  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }
  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }
  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}

/* ********** */
/* First counter example */
.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0rem;
  font-size: 2rem;
  font-weight: bold;
  margin: 6rem;
}

.counter * {
  font-size: inherit;
  padding: 0.8rem;
}

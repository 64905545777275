.title_primary :local {
  font-size: 64px;
  font-family: 'NCTTorin-ExtraBold';
  margin: 0% 10% 0% 10%;
  text-align: center;

  /** TEXT GRADIENT */
  background: linear-gradient(45deg, #fbda61 0%, #ff5acd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title_secondary :local {
  font-size: 28px;
  font-family: 'NCTTorin-ExtraBold';
  margin: 0% 10% 0% 10%;
  text-align: center;

  /** TEXT GRADIENT */
  background: linear-gradient(45deg, #fbda61 0%, #ff5acd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title_primary_simple :local {
  font-size: 64px;
  color: #ffffff;
  font-family: 'NCTTorin-ExtraBold';
  margin: 0% 10% 0% 10%;
  text-align: center;
}

.title_primary_white :local {
  font-size: 48px;
  color: #ffffff;
  font-family: 'NCTTorin-ExtraBold';
  margin: 0% 10% 0% 10%;
  text-align: center;
}

.title :local {
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  font-family: 'NCTTorin-Bold';
}

.title_black {
  font-size: 24px;
  color: #000000;
  text-decoration: none;
  font-family: 'NCTTorin-Bold';
}

.title_white {
  font-size: 24px;
  color: #ffffff;
  text-decoration: none;
  font-family: 'NCTTorin-Bold';
}

.text :local {
  font-size: 20px;
  color: #555555;
  font-family: 'NCTTorin-Regular';
  margin: 0% 15% 0% 15%;
  text-align: center;
}

.text_white :local {
  font-size: 18px;
  color: #ffffff;
  font-family: 'NCTTorin-Regular';
  margin: 0% 15% 0% 15%;
  text-align: center;
}

.text_no_margin {
  font-size: 14px;
  color: #909090;
  font-family: 'NCTTorin-Regular';
}

.text_dark_mode :local {
  font-size: 14px;
  color: #808080;
  font-family: 'NCTTorin-Light';
  margin: 0% 15% 0% 15%;
  text-align: center;
}

.flex_row {
  display: flex;
  justify-content: center;
}

.button-style {
  display: inline-block; /* Allow the button to shrink or expand based on content */
  height: 60px;
  background: black;
  border-radius: 30px;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 0 20px; /* Add padding to give some space around the text */
}

/* Media query for phones */
@media (max-width: 767px) {
  .title_primary_white :local {
    font-size: 28px;
    margin: 0% 10% 0% 0%;
  }

  .text_dark_mode :local {
    font-size: 11px;
    margin: 0% 10% 0% 0%;
  }

  .title_primary_simple :local {
    font-size: 48px;
    margin: 0% 5% 0% 5%;
  }

  .text :local {
    font-size: 16px;
    margin: 5%;
  }

  .title_primary :local {
    font-size: 48px;
    margin: 0% 0% 0% 0%;
  }
}
